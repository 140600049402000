// TODO: Initial refactoring. Will clean up in future PR.
import { theme as loftTheme } from "@xometry/xometry_loft";

const colors = {
  ...loftTheme.colors,
};

const breakpoints = [375, 576, 768, 1024, 1280, 1600, 1920, 2560];

const largerThan = {
  // New breakpoints below. Will remove and clean up breakpoints above
  xs: `@media (min-width: ${breakpoints[0]}px)`, // 375
  sm: `@media (min-width: ${breakpoints[1]}px)`, // 576
  md: `@media (min-width: ${breakpoints[2]}px)`, // 768
  lg: `@media (min-width: ${breakpoints[3]}px)`, // 1024
  xl: `@media (min-width: ${breakpoints[4]}px)`, // 1280
  "2xl": `@media (min-width: ${breakpoints[5]}px)`, // 1600
  "3xl": `@media (min-width: ${breakpoints[6]}px)`, // 1920
  "4xl": `@media (min-width: ${breakpoints[7]}px)`, // 2560
};

const smallerThan = {
  // New breakpoints below. Will remove and clean up breakpoints above
  xs: `@media (max-width: ${breakpoints[0] - 1}px)`,
  sm: `@media (max-width: ${breakpoints[1] - 1}px)`,
  md: `@media (max-width: ${breakpoints[2] - 1}px)`,
  lg: `@media (max-width: ${breakpoints[3] - 1}px)`,
  xl: `@media (max-width: ${breakpoints[4] - 1}px)`,
  "2xl": `@media (max-width: ${breakpoints[5] - 1}px)`,
  "3xl": `@media (max-width: ${breakpoints[6] - 1}px)`,
  "4xl": `@media (max-width: ${breakpoints[7] - 1}px)`,
};

const tallerThan = {
  xs: `@media (min-height: ${breakpoints[0]}px)`, // 375
  sm: `@media (min-height: ${breakpoints[1]}px)`, // 576
  md: `@media (min-height: ${breakpoints[2]}px)`, // 768
  lg: `@media (min-height: ${breakpoints[3]}px)`, // 1024
  xl: `@media (min-height: ${breakpoints[4]}px)`, // 1280
  "2xl": `@media (min-height: ${breakpoints[6]}px)`, // 1600
  "3xl": `@media (min-height: ${breakpoints[7]}px)`, // 1920
  "4xl": `@media (min-height: ${breakpoints[8]}px)`, // 2560
};

const shorterThan = {
  xs: `@media (max-height: ${breakpoints[0] - 1}px)`,
  sm: `@media (max-height: ${breakpoints[1] - 1}px)`,
  md: `@media (max-height: ${breakpoints[2] - 1}px)`,
  lg: `@media (max-height: ${breakpoints[3] - 1}px)`,
  xl: `@media (max-height: ${breakpoints[4] - 1}px)`,
};

const spacing = {
  "0.5": "0.125rem" /* 2px */,
  "1": "0.25rem" /* 4px */,
  "1.5": "0.375rem" /* 6px */,
  "2": "0.5rem" /* 8px */,
  "2.5": "0.625rem" /* 10px */,
  "3": "0.75rem" /* 13px */,
  "3.5": "0.875rem" /* 14px */,
  "4": "1rem" /* 16px */,
  "5": "1.25rem" /* 20px */,
  "6": "1.5rem" /* 24px */,
  "7": "1.75rem" /* 28px */,
  "8": "2rem" /* 32px */,
  "9": "2.25rem" /* 36px */,
  "10": "2.5rem" /* 40px */,
  "11": "2.75rem" /* 44px */,
  "12": "3rem" /* 48px */,
  "14": "3.5rem" /* 56px */,
  "16": "4rem" /* 64px */,
  "18": "4.5rem" /* 72px */,
  "20": "5rem" /* 80px */,
  "24": "6rem" /* 96px */,
  "28": "7rem" /* 112px */,
  "32": "8rem" /* 128px */,
  "36": "9rem" /* 144px */,
  "40": "10rem" /* 160px */,
  "44": "11rem" /* 176px */,
  "48": "12rem" /* 192px */,
  "52": "13rem" /* 208px */,
  "56": "14rem" /* 224px */,
  "60": "15rem" /* 240px */,
  "64": "16rem" /* 256px */,
  "72": "18rem" /* 288px */,
  "80": "20rem" /* 320px */,
  "96": "24rem" /* 384px */,
};

const maxWidth = "1216px";
const narrowWidth = `calc(${breakpoints[3]}px + ${spacing[8]})`;

const narrowContainer = "1008px";

const gutter = spacing[4];

const sliceSpacing = spacing[16];
const borderRadius = spacing[2];

const fonts = '"Open Sans", "Helvetica", "Arial", "sans-serif"';

// Will propose adding this to Loft, but this large a font size will
// most likely be primarily used on Marketing site. - Ryan Rivera
const fontSizes = [...Object.values(loftTheme.fontSizes), "48px"];

const text = {
  xs: `font-size: 0.75rem; line-height: 1rem;`,
  sm: `font-size: 0.875rem; line-height: 1.25rem;`,
  base: `font-size: 1rem; line-height: 1.5rem;`,
  lg: `font-size: 1.125rem; line-height: 1.75rem;`,
  xl: `font-size: 1.25rem; line-height: 1.75rem;`,
  "2xl": `font-size: 1.5rem; line-height: 2rem;`,
  "3xl": `font-size: 1.875rem; line-height: 2.25rem;`,
  "4xl": `font-size: 2.25rem; line-height: 2.5rem;`,
  "5xl": `font-size: 3rem; line-height: 1rem;`,
  "6xl": `font-size: 3.75rem; line-height: 1rem;`,
  "7xl": `font-size: 4.5rem; line-height: 1rem;`,
  "8xl": `font-size: 6rem; line-height: 1rem;`,
  "9xl": `font-size: 8rem; line-height: 1rem;`,
};

const leading = {
  none: 1,
  tight: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2,
};

const space = [...Object.values(loftTheme.space)];

const fontWeights = {
  ...loftTheme.fontWeights,
};

const boxShadow = {
  small: "0 4px 4px 2px rgba(0, 0, 0, 0.10)",
};

const gradient = {
  lightBlue: `linear-gradient(119deg, #F6F9FF 14.15%, #F5F8FD 26.17%, #F5F8FD 50.36%, #E6F0FD 68.64%)`,
};

const headerHeight = {
  sm: "62px",
  xl: "102px",
};

const theme = {
  colors,
  fonts,
  maxWidth,
  narrowWidth,
  narrowContainer,
  breakpoints,
  largerThan: largerThan,
  smallerThan: smallerThan,
  tallerThan: tallerThan,
  shorterThan: shorterThan,
  fontSizes,
  text,
  space,
  fontWeights,
  gutter,
  sliceSpacing,
  borderRadius,
  spacing,
  boxShadow,
  leading,
  gradient,
  headerHeight,
};

export default theme;
